import { Link, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import preview1 from 'assets/marketPlace/preview-1.png'
import { useEffect, useState } from "react";
import { useGetAllTemplatesByAppId, useGetAppById } from "framework/api/methods";
import { Loader } from "components/atoms";

export const DesignsPage= () => {
  const navigate = useNavigate()
 const params = useParams()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [templateList, setTemplateList] = useState([])

  const [appName, setAppName] = useState(sessionStorage.getItem('app-name'))

 useEffect(() => {
  if(sessionStorage.getItem('category_1')===null){
    navigate(`/designer/${params.id}/product`)
  }
 }, [sessionStorage])

 
 const { mutate: getAllTemplatesByAppIdMutate, isLoading: getAllTemplatesByAppIdIsLoading } = useGetAllTemplatesByAppId()

 const getAllTemplatesByAppId = () => {
  getAllTemplatesByAppIdMutate({
    appId: params.id || '',
    category_1: sessionStorage.getItem('category_1') || "null",
    category_2: sessionStorage.getItem('category_2') || "null",
    category_3: sessionStorage.getItem('category_3') || "null",
    category_4: sessionStorage.getItem('category_4') || "null"
   }, {
     onSuccess: ({ data: successData }: any) => {
      setTemplateList(successData?.data)
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
     },
   })
 }

 useEffect(() => {
  params.id && getAllTemplatesByAppId()
 },[params]);

 const { mutate: getAppByIdMutate, isLoading: getAppByIdIsLoading, } = useGetAppById()

 const getAppById = () => {
  getAppByIdMutate({
    appId: params.id?.toString() || ''
   }, {
     onSuccess: ({ data: successData }: any) => {
      setAppName(successData?.data[0]?.app_name)
      sessionStorage.setItem('app-name',successData?.data[0]?.app_name || '')
     },
     onError: ({ response: { data: errData }, response }: any) => {
       if(response?.status===405 || response?.status===403 || response?.status===501 ){
        if(response?.status===403 ){
          notify('Please login to continue.', 'error')
        }else{
          notify('Session expired.', 'error')
        }
         setTimeout(()=>{
           navigate('/auth/login')
           localStorage.clear()
         }, 2000);
       }else{
         notify(errData?.message || 'Something went wrong.', 'error')
       }
       
     },
   })
 }

 useEffect(() => {
  if(params.id){
    getAppById()
  }
 }, [params.id])


  return (
    <div className='flex flex-col bg-black h-full w-full py-4 px-6 md:px-32 md:py-12 text-white'>

      <span className="text-[40px] md:text-[42px] lg:text-[48px] hidden md:flex">{appName}</span>

      <div className="bg-black md:bg-[#292A2C] md:mt-5 h-full flex flex-col md:px-14 md:py-6 rounded-lg w-full overflow-y-auto hidescrl">
          <div className="w-full flex justify-between items-start">
            <i 
              className="ri-arrow-left-line text-[24px] pb-5 md:pb-2" 
              onClick={()=>{
                navigate(-1)
              }}
            />

            <span className="text-[24px] md:hidden flex">{appName}</span>
          </div>

      <div className="text-[32px] leading-tight captions-medium">
        Designs
      </div>

      <div className="text-[14px] leading-tight captions-font pt-1 font-light">
        Select a given template from below or make your own design
      </div>

      <div className="pt-3 text-sm font-light captions-font">
      {[
    { category: sessionStorage.getItem('category_1'), path: `/designer/${params.id}/product` },
    { category: sessionStorage.getItem('category_2'), path: `/designer/${params.id}/occasion` },
    { category: sessionStorage.getItem('category_3'), path: `/designer/${params.id}/style` }
  ]
    .filter(item => item.category && item.category !== 'null')
    .map((item, index, array) => (
      <span key={index}>
        <Link to={item.path} className="category-link">
          {item.category}
        </Link>
        {index < array.length - 1 && ' / '}
      </span>
    ))}
      </div>

      {getAllTemplatesByAppIdIsLoading && <Loader  className="mt-10"/>}

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 col-span-2 md:col-span-3 lg:col-span-4 gap-x-2 gap-y-7 md:gap-y-4 mt-10 overflow-scroll hidescrl">
      {templateList?.map((template: any, idx)=>
          <div className="flex flex-col justify-center items-center w-full" 
            key={idx}
            onClick={()=>{
              navigate(`/designer/${params.id}/personalized/${template.templateID}`)
            }}>
            <img src={template.coverImage} alt={`coverImage`+idx} className='w-[190px] h-[190px] rounded bg-gray-200'/>
          </div>
        )}    
      </div>
      
      {!getAllTemplatesByAppIdIsLoading && templateList?.length==0 && <div className="w-full justify-center items-end flex captions-font">No items found.</div>}     
        
      </div>

    
        
    </div>
  )
  
}

export default DesignsPage
